import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://buzcatch.pythonanywhere.com/api/homepage/"
        );
        setHomeData(response.data);
      } catch (error) {
        console.error("Error fetching blog data", error);
        setError("Failed to load blogs");
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <HomeContext.Provider value={{ homeData, loading, error }}>
      {children}
    </HomeContext.Provider>
  );
};
