import React from "react";
import Hblog from "./Hblog";
// import HAbout from "./HAbout"
import Hero from "./hero/Hero";
import About from "./Ab";
// import Hprice from "./Hprice"
// import Testimonal from "./testimonal/Testimonal"
import { BlogProvider } from "../blog/BlogContext";
import { HomeProvider } from "./HomeContext";
import Marquee from "./hero/Marquee";

const Home = () => {
  return (
    <>
      <HomeProvider>
        <Hero />
        <About />
        {/* <HAbout /> */}
        {/* <Testimonal /> */}
        <BlogProvider>
          <Hblog />
        </BlogProvider>
        {/* <Hprice /> */}
      </HomeProvider>
    </>
  );
};

export default Home;
