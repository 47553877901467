import React, { useEffect, useState } from "react";
import axios from "axios";
import Masonry from "react-masonry-css";
import "./Gallery.css"; // Import a CSS file for styling

const Gallery = () => {
  const [data, setData] = useState([]);
  console.log(data);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://buzcatch.pythonanywhere.com/api/academic/"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <>
      {data.map((images) => (
        <ImageSection
          images={images.galleries}
          heading={images.category_name}
        />
      ))}
    </>
  );
};

export default Gallery;

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

const ImageSection = function ({ images = [], heading = "" }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          borderRadius: "5px",
          paddingBlock: "8px",
          marginBottom: "2rem",
        }}
      >
        <h2>{heading}</h2>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image) => (
          <div key={image.id}>
            <img
              src={`https://buzcatch.pythonanywhere.com${image.image}`}
              alt={`Gallery ${image.id}`}
              style={{
                width: "100%", // Make the image responsive
                height: "auto", // Maintain the aspect ratio
                objectFit: "contain", // Ensure the full image is visible without cropping
                borderRadius: "5px", // Optional for rounded corners
              }}
            />
            {image.description && (
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#96022c",
                  padding: "8px",
                }}
              >
                {image?.description}
              </div>
            )}
          </div>
        ))}
      </Masonry>
    </>
  );
};
