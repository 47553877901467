import React, { useContext } from "react";
import Heading from "../common/heading/Heading";
import "../about/about.css";
import { homeAbout } from "../../dummydata";
// import Awrapper from "../about/Awrapper"
import Abimg from "../../img/i/1.jpg";
import { Link } from "react-router-dom";
import { HomeContext } from "./HomeContext";

const About = () => {
  const { homeData, loading, error } = useContext(HomeContext);
  const latestNews = homeData?.latest_blog?.at(-1);
  if (loading) return <div> Loading... </div>;
  return (
    <>
      <section className="aboutHome">
        <div className="container flexSB ">
          <div className="left row">
            <img src={Abimg} alt="" />
          </div>
          <div className="right row">
            <Heading
              subtitle="A Family Of Learning"
              title="Excellence Through Education"
            />
            <div className="items">
              {/*  */}
              <div className="item homeAboutNews ">
                <div className="img">
                  <img src={homeAbout[0].cover} alt="Latest News" />
                </div>
                <div className="text">
                  <h2>{homeAbout[0].title}</h2>
                  <p>{homeAbout[0].desc}</p>
                </div>
              </div>
              {/*  */}
              <div className="item homeAboutNews ">
                <div className="img">
                  <img
                    src="https://img.icons8.com/ios/80/000000/diploma.png"
                    alt="Latest News"
                  />
                </div>
                <div className="text">
                  <h2>LATEST NEWS</h2>
                  <p>{latestNews?.blog_content}</p>
                </div>
              </div>
              {/*  */}
              <div className="item homeAboutNews ">
                <div className="img">
                  <img src={homeAbout[2].cover} alt="Latest News" />
                </div>
                <div className="text">
                  <h2>{homeAbout[2].title}</h2>
                  <p>{homeAbout[2].desc}</p>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* <Awrapper /> */}
    </>
  );
};

export default About;
