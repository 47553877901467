import React, { useContext } from "react";
import { BlogContext } from "./BlogContext";
import { Link, useNavigate } from "react-router-dom";

const BlogCard = () => {
  const { blogs, loading, error } = useContext(BlogContext);
  console.log("BLOGS", blogs);
  const navigate = useNavigate();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {blogs.map((val) => (
        <div className="items shadow" key={val.id}>
          <div className="img">
            <img
              src={`https://buzcatch.pythonanywhere.com${val.blog_image}`}
              alt={val.blog_title}
              loading="lazy"
            />
          </div>
          <div className="text">
            {val.blog_file && (
              <div>
                <div
                  onClick={() => {
                    window.open(
                      `https://buzcatch.pythonanywhere.com${val.blog_file}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                  style={{
                    background: "#97022c",
                    color: "white",
                    borderRadius: "7px",
                    padding: "8px",
                    textAlign: "center",
                    width: "180px",
                    cursor: "pointer",
                    marginLeft: "auto",
                    marginBottom: "10px",
                  }}
                >
                  Download
                </div>
              </div>
            )}
            <div className="admin flexSB">
              <span>
                <i className="fa fa-user"></i>
                <label>{val.type || "Admin"}</label>
              </span>
              <span>
                <i className="fa fa-calendar-alt"></i>
                <label>{new Date(val.posted_date).toLocaleDateString()}</label>
              </span>
            </div>
            <h1>{val.blog_title}</h1>
            <p>{val.blog_content}</p>
            <p>
              <Link to={`/blog/${val.id}`}>read more...</Link>
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default React.memo(BlogCard);
