import React, { useEffect, useState } from "react";
import Heading from "../common/heading/Heading";
import "./about.css";
// import Awrapper from "./Awrapper";
import TeamSection from "./Team";
import Img from "../../img/i/2.jpg";
import axios from "axios";

const AboutCard = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [paraList, setParaList] = useState({});
  useEffect(() => {
    axios
      .get("https://buzcatch.pythonanywhere.com/api/aboutus/")
      .then((response) => {
        setParaList(response.data.aboutus_section.at(0));
        const image = response.data.aboutus_section[0].image;
        setImageUrl(`https://buzcatch.pythonanywhere.com${image}`);
      })
      .catch((error) => {
        console.error("There was an error fetching the image!", error);
      });
  }, []);

  return (
    <>
      <section className="aboutHome">
        <div className="container flexSB">
          {/* <div className="left row">
            <img src={Img} alt="" />
          </div> */}
          <div className="" style={{ paddingTop: "35px" }}>
            <Heading
              subtitle="A Brief History"
              title="Excellence Through Education"
            />
            <div className="text" style={{ color: "grey" }}>
              {}
              <br />
              <p style={{ textAlign: "justify" }}>
                {paraList.aboutus_one}
              </p>{" "}
              <br />
              <p style={{ textAlign: "justify" }}>{paraList.aboutus_two}</p>
              <br />
              <p style={{ textAlign: "justify" }}>{paraList.aboutus_three}</p>
              <br />
              {/* <p style={{ textAlign: "justify" }}>
                The first batch of the class ten in Indian Certificate of Secondary Education (ICSE) wrote examination in the year of 2015 and they came out with an outstanding result. In the year, 2019-2020 was our 5th batch of ICSE examination. Our school consistently produces the exemplary result every year. The Management, the Parents and all the well-wishers are much pleased with the functioning of Vimalagiri International School. Within a short span of time, the school has got a good reputation and honour among the people.
              </p><br /> */}
            </div>
          </div>
        </div>
        <div className="left">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="About Us"
              style={{ padding: "0px 120px 0px", height: "560px" }}
            />
          )}
        </div>
      </section>

      <TeamSection />

      {/* <Awrapper /> */}
    </>
  );
};

export default AboutCard;
