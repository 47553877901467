import React, { useContext } from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import { HomeContext } from "../HomeContext";

const Hero = () => {
  const { homeData, loading, error } = useContext(HomeContext);
  const bannerdata = homeData?.banner_sections?.[0];

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading
              subtitle={bannerdata.content_one}
              title={bannerdata.content_two}
              footer={bannerdata.content_three}
            />

            <div className="button">
              <button className="primary-btn">
                GET STARTED NOW <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin1"></div>
    </>
  );
};

export default Hero;
